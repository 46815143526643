
import { defineComponent } from "vue-demi";
import Order from "@/components/owner/order/index.vue";

export default defineComponent({
  name: "OwnerOrderView",
  components: {
    Order,
  },
});
